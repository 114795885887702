
import {defineComponent, onMounted, reactive, toRefs, computed, ref} from "vue";
import ApiArticle from "@/request/apis/api_article";
import ApiLogistics from "@/request/apis/api_logistics";
import {articleTypes, IArticleCategory, IArticleItem} from "@/models/article_model";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons-vue'
import ImageView from '@/components/ImageView.vue'
import PageUtil from "@/utils/page_util";
import {IArticlePlatform} from "@/models/logistics_article_model";
import ArticlePlatAdd from "./article-plat-add.vue"

interface IState {
  list: IArticlePlatform[],
  total: number;
  loading: boolean;
  searches: any
  editItem?: IArticleItem,
  articlePlatVisible: boolean
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '描述',
    dataIndex: "description",
    key: 'description',
  },
  {
    title: '标识',
    dataIndex: 'tag',
    key: 'tag',
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
    align: 'right'
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: 250
  },
];

export default defineComponent({
  name: 'ArticleList',
  components: {EditOutlined, CheckOutlined, CloseOutlined, ImageView, ArticlePlatAdd},
  setup() {
    const platSet = ref();
    const state: IState = reactive({
      platformList: [],
      list: [],
      total: 0,
      loading: false,
      editItem: undefined,
      searches: {
        page: 1,
        limit: 10
      },
      articlePlatVisible: false
    })


    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取平台列表
    const getList = async () => {
      let params = {...state.searches}
      params.page_no = params.page;
      params.page_size = params.limit;
      StorageUtil.setParams(params)
      delete params.total
      delete params.pid
      state.loading = true;
      console.log(params)
      const res = await ApiLogistics.articlePlatList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.list = res.data?.list || []
        state.total = res.data?.count || 0;
      }
    }

    // 删除文章
    const onDel = async (item: IArticleItem) => {
      const res = await ApiArticle.delArticle(item.id)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 审核文章
    const onAudit = async (item: IArticleItem, status: number) => {
      const res = await ApiArticle.auditArticle(item.id, status)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    const onRecommend = async (item: IArticleItem) => {
      const res = await ApiArticle.recommendArticle(item.id, Number(!Boolean(item.is_recommend)))
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 排序
    const onSort = async (e: any) => {
      if (!state.editItem) return
      const res = await ApiArticle.sortArticle(state.editItem.id, e)
      if (res) {
        state.editItem = undefined
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 设置排序item
    const setSortEditItem = (item: IArticleItem) => {
      if (state.editItem && item.id === state.editItem.id) {
        state.editItem = undefined
      } else {
        state.editItem = item
      }
    }

    // 去新增编辑
    const goSet = (item?: IArticlePlatform) => {
      let params = {}
      if (item) {
        params = {id: item.id}
      }
      router.push({path: '/articlePlat/detail', query: params}).then()
    }
    // 详情
    const goDetail = (item: IArticleItem) => {
      router.push({path: '/article/detail', query: {id: item.id}}).then()
    }

    // 添加平台
    const addPlat = () => {
      state.articlePlatVisible = true;
    };

    // 查看或修改平台
    const updatePlat = () => {

    };

    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch();
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      goSet,
      onDel,
      onRecommend,
      onAudit,
      goDetail,
      pagination,
      handleTableChange,
      articleTypes,
      onSort,
      setSortEditItem,
      addPlat,
      updatePlat
    }
  }
})
