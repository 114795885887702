import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    onOk: _ctx.onSubmit,
    visible: _ctx.platVisible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.platVisible) = $event)),
    title: "添加平台"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        ref: "formRef",
        model: _ctx.formState,
        rules: _ctx.rules,
        "label-col": _ctx.labelCol,
        "wrapper-col": _ctx.wrapperCol
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            ref: "tag",
            label: "唯一标识",
            name: "tag"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formState.tag,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.tag) = $event)),
                placeholder: "请输入唯一标识"
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 512),
          _createVNode(_component_a_form_item, {
            ref: "description",
            label: "描述",
            name: "description"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formState.description,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.description) = $event)),
                placeholder: "请输入描述"
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }, 8, ["model", "rules", "label-col", "wrapper-col"])
    ]),
    _: 1
  }, 8, ["onOk", "visible"]))
}