
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs, computed} from "vue"
import type {UnwrapRef} from "vue"
import {IArticleCategory, IArticleUpdate, articleTypes} from "@/models/article_model"
import ApiLogistics from "@/request/apis/api_logistics"
import UploadComponent from "@/components/UploadComponent.vue"
import {message} from "ant-design-vue/es"
import {useRoute, useRouter} from "vue-router"
import EditorComponent from '@/components/editor/Editor.vue'
import {IArticlePlatform} from "@/models/logistics_article_model";

interface IState {
  category: IArticleCategory[],
  loading: boolean;
}

// 新增编辑文章
export default defineComponent({
  name: "ArticlePlatSet",
  components: {UploadComponent, EditorComponent},
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const route = useRoute()
    const router = useRouter()
    let editId: any;
    const formRef = ref()
    const editorComponentRef = ref()
    const formState: UnwrapRef<IArticlePlatform> = reactive({
      tag: "",
      description: "",
      cate_ids: []
    })
    const rules = {
      tag: [{required: true, message: "请输入唯一标识"},],
      description: [{required: true, message: "请输入描述"},],
    }
    const platVisible = computed({
      get() {
        return props.visible;
      },
      set(val:boolean) {
        context.emit("update:visible", val);
      }
    })

    const handleOk = (e:any) => {
      context.emit("ok", e);
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            let params = {...toRaw(formState)}
            if (editId) {
              params.id = editId
              onAdd(params)
            } else {
              onAdd(params)
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }

    const init = (data:IArticlePlatform) => {
      formState.id = data.id;
      formState.tag = data.tag;
      formState.cate_ids = data.cate_ids;
      formState.description = data.description;
    }

    const state: IState = reactive({
      category: [],
      loading: false,
    })

    // 新增
    const onAdd = async (params: any) => {
      state.loading = true
      const res = await ApiLogistics.updateOrAddArticlePlat(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    const goBack = () => {
      router.back()
    }

    onMounted(() => {

    })

    return {
      formRef,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      other: "",
      formState,
      rules,
      onSubmit,
      resetForm,
      goBack,
      articleTypes,
      ...toRefs(state),
      editorComponentRef,
      platVisible,
      init
    }
  },
})
